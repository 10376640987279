import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsandConditions.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "parent" */ '../components/Products.vue'),
    children: [
      {
        path: 'aggregates',
        name: 'products:aggregates',
        component: () => import(/* webpackChunkName: "aggregates" */ '../views/products/Aggregates.vue'),
      },
      {
        path: 'roadbases',
        name: 'products:roadbases',
        component: () => import(/* webpackChunkName: "roadbases" */ '../views/products/Roadbases.vue'),
      },
      {
        path: 'rock',
        name: 'products:rock',
        component: () => import(/* webpackChunkName: "rock" */ '../views/products/Rock.vue'),
      },
      {
        path: 'dust',
        name: 'products:dust',
        component: () => import(/* webpackChunkName: "dust" */ '../views/products/Dust.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
