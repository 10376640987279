<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="hero-container">
        <h1>Mt Marrow Blue Metal Quarries</h1>
        <h2>Excellence in crushed rock products.</h2>
        <router-link :to="{ name: 'contact' }" class="btn-get-started">Contact Us</router-link>
      </div>
    </section>
    <!-- End Hero -->
    <main id="main">
      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-11">
              <div class="row justify-content-center">
                <div
                  class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch"
                >
                  <div class="count-box py-5">
                    <img
                      src="@/assets/img/nata.gif"
                      width="48"
                      class="img-fluid"
                    />
                    <p>
                      NATA Accredited<br />
                      Laboratory
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch"
                >
                  <div class="count-box py-5">
                    <img
                      src="@/assets/img/5-ticks.webp"
                      width="48"
                      class="img-fluid"
                    />
                    <p>
                      ISO 9001 Certified<br />
                      Quality System
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch"
                >
                  <div class="count-box pb-5 pt-0 pt-lg-5">
                    <i class="bi bi-clock"></i>
                    <span class="purecounter">35+</span>
                    <p>Years of Experience</p>
                  </div>
                </div>

                <div
                  class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch"
                >
                  <div class="count-box pb-5 pt-0 pt-lg-5">
                    <i class="bi bi-truck"></i>
                    <span class="purecounter">5,000,000+</span>
                    <p>Tonnes Delivered</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-6 video-box align-self-baseline position-relative"
            >
              <img src="@/assets/img/loader.jpg" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 pt-3 pt-lg-0 content">
              <p>
                Mt Marrow Blue Metal Quarries is a producer and supplier of high
                quality quarried products including concrete and asphalt
                aggregates, rail ballast, and high quality roadbases to civil
                works projects, government and private customers throughout
                South East Queensland.
              </p>
              <p>
                The Quarry's main site is located approximately 15km west of
                Ipswich and 55km south west of Brisbane, between the townships
                of Rosewood, Walloon and Marburg. The quarry is ideally situated
                to service Ipswich and the surrounding area, Western suburbs of
                Brisbane, the Brisbane Valley, Lockyer Valley and the Scenic Rim
                areas. Easiest access to the quarry is off the Warrego Highway
                at Haigslea however access is also available from the Cunningham
                Highway via Amberley. For more details on accessing the quarry
                please visit the About page.
              </p>
              <p>
                The Company's quality system is certified to the ISO 9001
                standard and operates a full time NATA accredited soils
                laboratory on site. This enables the quarry to provide
                consistent, high quality products backed by certified test
                results for applicable products. The laboratory is also able to
                work with customers in developing products designed to meet
                customer's specific requirements for projects.
              </p>
              <p>
                Our experienced staff are able to arrange delivery of quantities
                ranging from a few tonnes through to multi-thousand tonnes per
                day through the Company's network of sub-contract haulage
                companies. Contact the Quarry's despatch department to discuss
                your haulage requirements. Alternatively customers are welcome
                to arrange 3rd party haulage or utilise their own vehicle
                resources.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- End About Section -->

      <!-- ======= Cta Section ======= -->
      <section id="cta" class="cta">
        <div class="container" data-aos="fade-in">
          <div class="text-center">
            <h3>We're here to help</h3>
            <p>
              Our staff are ready to assist with your next project, big or
              small.
            </p>
            <router-link :to="{ name: 'contact' }" class="cta-btn">Contact Us</router-link>
          </div>
        </div>
      </section>
      <!-- End Cta Section -->

      <!-- ======= Services Section ======= -->
      <section id="services" class="services">
        <div class="container">
          <div class="section-title pt-5">
            <h2>Our Products</h2>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="icon-box">
                <div class="icon">
                  <img
                    src="@/assets/img/agg-icon.jpg"
                    class="img-fluid"
                    width="64"
                  />
                </div>
                <h4 class="title"><router-link :to="{ name: 'products:aggregates' }">Aggregates</router-link></h4>
                <p class="description">
                  Suitable for Concrete, Asphalt, Drainage and Pipework. We
                  produce and stock a range of grades and sizes.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box">
                <div class="icon">
                  <img
                    src="@/assets/img/roadbase-icon.jpg"
                    class="img-fluid"
                    width="64"
                  />
                </div>
                <h4 class="title"><router-link :to="{ name: 'products:roadbases' }">Roadbases</router-link></h4>
                <p class="description">
                  From national highways to housing estates, driveways and
                  building pads, we have roadbase products to meet your needs.
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="icon-box">
                <div class="icon">
                  <img
                    src="@/assets/img/rock-icon.jpg"
                    class="img-fluid"
                    width="64"
                  />
                </div>
                <h4 class="title"><router-link active-class="active" :to="{ name: 'products:rock' }">Rock</router-link></h4>
                <p class="description">
                  Rock products are available from all stages of our quarrying
                  process. Boulders, shot rock, riprap, and graded rock products
                  are all available.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box">
                <div class="icon">
                  <img
                    src="@/assets/img/ballast-icon.jpg"
                    class="img-fluid"
                    width="64"
                  />
                </div>
                <h4 class="title"><router-link active-class="active" :to="{ name: 'products:aggregates' }">Railway Ballast</router-link></h4>
                <p class="description">
                  A long term specialty product of the quarry. We work closely
                  with our rail customers to ensure they get a durable and
                  effective product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services Section -->
    </main>
    <!-- End #main -->
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
