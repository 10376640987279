<template>
  <div>
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top d-flex align-items-center">
      <div class="container d-flex align-items-center justify-content-between">
        <div class="logo">
          <router-link :to="{ name: 'home' }" href="/">
            <img src="@/assets/img/top-logo.png" alt="" class="img-fluid">
          </router-link>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li><router-link active-class="active" :to="{ name: 'home' }">Home</router-link></li>

            <li><router-link active-class="active" :to="{ name: 'products:aggregates' }">Aggregates</router-link></li>
            <li><router-link active-class="active" :to="{ name: 'products:roadbases' }">Roadbases</router-link></li>
            <li><router-link active-class="active" :to="{ name: 'products:rock' }">Rock</router-link></li>
            <li><router-link active-class="active" :to="{ name: 'products:dust' }">Dust</router-link></li>

            <li><router-link active-class="active" :to="{ name: 'about' }">About</router-link></li>

            <li><router-link :to="{ name: 'contact' }" class="getstarted">Contact Us</router-link></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>
    <!-- End Header -->
    <transition name="fade">
      <router-view/>
    </transition>

    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">

            <div class="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                237 Mt Marrow Quarry Rd <br />
                Mt Marrow, QLD 4306<br />
                <br />
                <strong>Phone:</strong> (07) 54 644 644<br />
                <strong>Email:</strong> sales@mtmarrow.com<br />
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-info">
              <h4>Company Details</h4>
              <p>
                Mt Marrow Blue Metal Quarries Pty. Ltd.<br />
                ABN: 64 004 879 930<br />
                ACN: 004 879 930<br/>
              </p>
            </div>
            <div class="col-lg-3 col-md-6 footer-info">
              <h4>Hours of Operation</h4>
              <table id="hours" class="mx-auto">
                <tr>
                  <td>Monday to Friday</td>
                  <td>7:00am - &nbsp;4:30pm</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>7:00am - 11:00am</td>
                </tr>
                <tr>
                  <td>Sunday</td>
                  <td>Closed</td>
                </tr>
              </table>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li><router-link :to="{ name: 'about' }">About us</router-link></li>
                <li><router-link :to="{ name: 'terms' }">Terms & Conditions</router-link></li>
                <li><a href="/docs/Mt_Marrow_Privacy_Policy.pdf">Privacy policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer -->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
};
</script>

<style scoped>
#hours td {
  padding-right: 2em;
}
</style>
